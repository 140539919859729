<template>
  <div id="app">
    <topHeader />
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import topHeader from "@/components/headersAndFooters/topHeader.vue";

export default {
  name: "App",
  components: {
    topHeader,
  },
  setup() {
    return {};
  },
};
</script>

<style>
/* These styles should be applied globally */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#app {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Ensure topHeader is flush with the top of the viewport */
topHeader {


  right: 0;
  z-index: 1000;
}

@media only screen and (max-width: 400px) {
  #app, .content-wrapper {
    width: 100vw;
    overflow-x: hidden;
  }
}
</style>
