<template id="main">
  <div class="video-container" id="mainVideo">
    <div class="wistia_responsive_padding">
      <div class="wistia_responsive_wrapper">
        <div
          class="wistia_embed wistia_async_8vpglzn4hr autoPlay=true muted=true loop=true"
          id="wPlayer"
        >
          &nbsp;
        </div>
      </div>
    </div>
    <div id="videoOverlay" class="overlay">
      <div id="test"></div>
      <div id="mainVideoSlogan">Your Safety program in one place</div>
      <p id="sloganParagraph">Safe Eye Safety Management Software</p>
      
      <div class="flexRow">
        <button class="vidBtn" id="playVidBtn" @click="playVideo">
          Play Video
        </button>

        <router-link
          to="/contactPage"
          id="bookDemoBtn"
        >Schedule demo</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "mainPageVideo",
    components: {
    },

    setup() {
      function playVideo() {
      }

      return { 
        playVideo
      };
    },
  };
</script>

<style scoped>
#main {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  /* Add this line to push the video down by the header height */
  /* margin-top: clamp(2rem, calc(1rem + 4vw), 4rem); */
}

.wistia_responsive_padding {
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  width: 100%;
}

.wistia_responsive_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wistia_embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  text-align: center;
  padding: 5vw;
  box-sizing: border-box;
  /* background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 100%); */
}

#mainVideoSlogan {
  text-shadow: 0px 2px 2px #5a5959;
  font-size: 5vw;
  font-weight: 800;
  margin-bottom: 2vw;
  font-family: vf, Arial, sans-serif;
  color: white;
  width: 100%;
}

#sloganParagraph {
  font-size: 3vw;
  font-weight: 600;
  font-family: vf, Arial, sans-serif;
  color: rgb(249, 246, 246);
  text-shadow: 0px 2px 2px #5a5959;
  width: 100%;
  margin-bottom: 3vw;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  width: 100%;
  margin-top: 2vw;
  gap: 1vw;
}

.vidBtn, #bookDemoBtn {
  margin: 0;
  font-size: 1.2vw;
  font-weight: 600;
  font-family: vf, Arial, sans-serif;
  color: rgb(249, 246, 246);
  padding: 0.8vw 1.5vw;
  border-radius: 0.5vw;
  text-decoration: none;
  white-space: nowrap;
  width: 18vw;
  height: 4.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  transition: all 0.3s ease;
}

.vidBtn {
  background-color: var(--primary-color);
  border: none;
}

#bookDemoBtn {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(249, 245, 245, 0.2);
}

/* Remove all media queries for now */
</style>
