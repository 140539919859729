<template id="mainHeaderMain">
  <div id="mainContainer">
    <div class="content-wrapper">
      <seLogo />
      <div id="rowContainer">
        <a href="/features" class="headerBtn">Features</a>
        <router-link
          to="/contactPage"
          class="headerBtn"
          id="bookDemoBtn"
        >Schedule demo</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import seLogo from "../logo/seLogo.vue";

  export default {
    name: "mainHeader",

    components: {
      seLogo,
    },

    data() {
      return {
        phoneNumber: '4034345865',
      };
    },
  };
</script>

<style scoped>
  #mainContainer {

    z-index: 1000;
    width: 100%;
    height: clamp(2rem, calc(1rem + 4vw), 4rem);
    background-color: rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

  }

  .content-wrapper {
    width: 100%;
    max-width: 100%; /* Change this line */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem clamp(0.5rem, calc(0.25rem + 2vw), 2rem);
    padding-left: calc(1rem + 0.5vw); /* Add this line */
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 100%); 

  }

  #mainContainer:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }

  #rowContainer {
    display: flex;
    align-items: center;
  }

  .headerBtn {
    color: rgba(255, 255, 255, 0.75);
    font-family: vf;
    font-size: clamp(0.75rem, calc(0.5rem + 1vw), 1.5rem);
    font-weight: 500;
    margin-left: clamp(0.5rem, calc(0.25rem + 1vw), 1.5rem);
    text-shadow: 0.05em 0.05em 0.075em rgba(0, 0, 0, 0.3);
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
  }

  .headerBtn:hover {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
  }

  @media (max-width: 400px) {
    .headerBtn {
      font-size: clamp(0.6rem, calc(0.4rem + 0.8vw), 0.9rem);
      margin-left: clamp(0.3rem, calc(0.15rem + 0.8vw), 0.8rem);
    }
  }

  /* Styles for seLogo component, adjust as needed */
  :deep(.se-logo) {
    width: clamp(2rem, calc(1.5rem + 4vw), 6rem);
    height: auto;
    margin-left: -0.5rem; /* Add this line to compensate for the logo's internal padding */
  }
</style>
