<template>
  <a class="container" href="/">
    <img
      id="loginImage"
      src="@/assets/images/icons/favicon-512x512.png"
      alt="Safe Eye Logo"
    />
    <div id="seText">Safe Eye</div>
  </a>
</template>

<script>
export default {
  name: "seLogo",
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  max-width: 24rem; /* Reduced from 30rem */
  padding: 0.4rem; /* Reduced from 0.5rem */
}

#loginImage {
  width: 12%; /* Reduced from 15% */
  max-width: 3.2rem; /* Reduced from 4rem */
  height: auto;
  border-radius: 0.2rem; /* Reduced from 0.25rem */
  box-shadow: rgba(0, 0, 0, 0.15) 1.56px 1.56px 2.08px; /* Reduced by 20% */
}

#seText {
  font-size: clamp(0.8rem, 3.2vw, 2rem); /* Reduced from 1rem, 4vw, 2.5rem */
  font-weight: 500;
  color: white;
  padding-left: 0.64rem; /* Reduced from 0.8rem */
  white-space: nowrap;
  text-shadow: rgba(0, 0, 0, 0.15) 1.56px 1.56px 2.08px; /* Reduced by 20% */
}

@media (max-width: 576px) {
  .container {
    padding: 0.2rem; /* Reduced from 0.25rem */
  }

  #loginImage {
    width: 9.6%; /* Reduced from 12% */
  }

  #seText {
    padding-left: 0.32rem; /* Reduced from 0.4rem */
  }
}
</style>
