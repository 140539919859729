<template>
  <!-- Calendly badge widget begin -->

  <!-- Calendly badge widget end -->
  <!-- Calendly inline widget begin -->
  <!-- <div
    class="calendly-inline-widget"
    data-url="https://calendly.com/d/z3h-r65-zgd"
    style="min-width: 320px; height: 630px"
  ></div> -->
  <!-- Calendly inline widget end -->
  <section>
    <h1 class="">Features</h1>
    <div class="sideBySideDiv" id="sideBySide">
      <div class="col__Container">
        <img
          class="appImage end"
          src="@/assets/images/appPics/formsImage.webp"
          alt="Safe Eye Logo"
        />
      </div>

      <div class="col__Container">
        <div class="floatingHeader">All forms organized in one place</div>
        <div class="largeHeader">
          Never again have missing or unorganized forms
        </div>
        <div class="sideBySideText">
          Forms are automatically organized and searchable by date and type.
          Backed up in the cloud easily accessible for the whole team.
        </div>
      </div>
    </div>

    <div class="sideBySideDiv" id="sideBySide">
      <div class="col__Container">
        <div class="floatingHeader">
          See and manage all worker's certifications
        </div>
        <div class="largeHeader">No more scattered certification records</div>
        <div class="sideBySideText">
          See at a glance active certifications , missing records , expiring
          records and more.
        </div>
      </div>
      <div class="col__Container right">
        <img
          class="appImage start"
          src="@/assets/images/appPics/certificatesImage.webp"
          alt="Safe Eye Logo"
        />
      </div>
    </div>

    <div class="sideBySideDiv" id="sideBySide">
      <div class="col__Container">
        <img
          class="appImage end"
          src="@/assets/images/appPics/docImage.webp"
          alt="Safe Eye Logo"
        />
      </div>

      <div class="col__Container">
        <div class="floatingHeader">Share important files company wide</div>
        <div class="largeHeader">No more missing manuals & documents</div>
        <div class="sideBySideText">
          With a few clicks all safety data sheets, OH&S manual, company safety
          manual are instantly in the pockets of all workers.
        </div>
      </div>
    </div>

    <div class="sideBySideDiv" id="sideBySide">
      <div class="col__Container">
        <div class="floatingHeader">Open documents in app</div>
        <div class="largeHeader">No more emailing large documents</div>
        <div class="sideBySideText">
          Open documents in app so workers are guaranteed to have the most up to
          date version and have ease of access.
        </div>
      </div>
      <div class="col__Container">
        <img
          class="appImage start"
          src="@/assets/images/appPics/pdfImage.webp"
          alt="Safe Eye Logo"
        />
      </div>
    </div>

    <div class="sideBySideDiv" id="sideBySide">
      <div class="col__Container">
        <img
          class="appImage end"
          src="@/assets/images/appPics/offlineImage.webp"
          alt="Safe Eye Logo"
        />
      </div>

      <div class="col__Container">
        <div class="floatingHeader">No Internet , no problem</div>
        <div class="largeHeader">Complete forms with or without internet</div>
        <div class="sideBySideText">
          Workers can still complete forms and be compliant even if they are not
          connected.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { reactive } from "vue";

  export default {
    name: "featuresPage",
    setup() {
      const state = reactive({
        message: "Hello, world!",
      });

      return {
        message: state.message,
      };
    },
  };
</script>

<style scoped>

  img {
    border-radius: 3.5rem;
    /* make image fit */
    object-fit: scale-down;
    width: 20rem;
  }

  .left {
    padding-right: 0rem;
    margin-right: 0rem;
    /* text-align: right; */
    /* align-items: flex-end; */
    justify-content: flex-start;
  }
  .right {
    /* text-align: left; */
    align-self: flex-start;
  }

  .end {
    align-self: flex-end;
  }
  .start {
    align-self: flex-start;
  }
  .appImage {
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .col__Container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 50%;
    padding: 3rem;
  }

  .sideBySideDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding: 5rem 0rem 5rem 0rem; */

    /* padding: 1rem; */
    /* justify-content: space-between; */
  }

  .pageHeader * {
    margin: 1rem;

    width: 20vw;
  }

  h1 {
    width: 50vw;
    text-align: center;
    margin: 0 auto;
    font-size: 12vw;
  }

  @media only screen and (max-width: 350px) {
  }
  @media only screen and (max-width: 450px) {
  }
  @media screen and (max-width: 450px) {
  }
  @media only screen and (max-width: 575px) {
    .pageHeader {
      font-size: 2rem;
    }
    .sideBySideDiv {
      flex-direction: column;
      align-items: center;
    }
    .left {
      justify-content: center;
      align-self: center;
    }
    .right {
      justify-content: center;
      align-self: center;
    }

    .end,
    .start {
      align-self: center;
    }
    .largeHeader {
      font-size: 2rem;
    }
    .sideBySideText {
      font-size: 1rem;
    }

    .appImage {
      width: 80%;
      border-radius: 5vw;
    }

    .col__Container {
      width: 70%;
      flex-start: center;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .sideBySideDiv {
      flex-direction: column;
      align-items: center;
    }
    .left {
      justify-content: center;
      align-self: center;
    }
    .right {
      justify-content: center;
      align-self: center;
    }

    .end,
    .start {
      align-self: center;
    }
    .largeHeader {
      font-size: 2rem;
    }
    .sideBySideText {
      font-size: 1rem;
    }

    .appImage {
      width: 80%;
      border-radius: 5vw;
    }

    .col__Container {
      flex-start: center;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .appImage {
      width: 80%;
      border-radius: 5vw;
    }
    .floatingHeader {
      font-size: 0.8rem;
    }
    .largeHeader {
      font-size: 1.5rem;
    }
    .sideBySideText {
      font-size: 1rem;
      margin-left: 0rem;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  }

  @media only screen and (max-width: 1499px) and (min-width: 1201px) {
  }
  @media only screen and (min-width: 1500px) {
    h1 {
      width: 50%;
      margin: 0 auto;
      font-size: 6vw;
    }
  }
</style>
