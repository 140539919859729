<template>
  <div class="container">
    <div id="topHeader">
      <div id="topHeaderPhoneNumber">
        <a class="topHeaderAnchor" href="tel:4034345865">
          <svg id="phoneImageSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/>
          </svg>
          <span id="phoneNumber">403-434-5865</span>
        </a>
      </div>
      <div class="topHeader__menu">
        <ul class="columnContainer">
          <li><a href="contactPage" class="topHeaderAnchor">Contact</a></li>
          <!-- <li><a href="#" class="topHeaderAnchor">Support</a></li> -->
          <li class="columnContainer">
            <a class="topHeaderAnchor" href="https://safe-eye.app/"
              >Login
              <img
                id="loginImage"
                src="@/assets/styles/characters/loginLogo/icons8-login-ios-16-96.png"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <mainHeader id="mainHeader" />
  </div>
</template>

<script>
import mainHeader from "@/components/headersAndFooters/mainHeader.vue";

export default {
  name: "topHeader",
  components: {
    mainHeader,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* Add these global styles at the top of your style section */
  html, body {
    margin: 0;
    padding: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000; /* Add this line */
 
    margin: 0;
    padding: 0;
  }

  #topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    background-color: black;
    color: var(--se-light);
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 16px;
    margin: 0; /* Add this line */
  }

  .content-wrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 clamp(0.5rem, calc(0.25rem + 2vw), 2rem);
    padding-left: calc(1rem + 0.5vw); /* Add this line to match mainHeader */
  }

  #topHeaderPhoneNumber {
    display: flex;
    align-items: center;
    margin-left: -0.5rem; /* Add this line to align with the logo in mainHeader */
    white-space: nowrap;
    animation: scale 0.7s infinite alternate;
    animation-play-state: paused;
  }

  #topHeaderPhoneNumber:hover {
    animation-play-state: running;
  }

  @keyframes scale {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
  }

  .topHeader__menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
  }

  .columnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
  }

  .columnContainer li {
    list-style-type: none;
    text-decoration: none;
  }

  .topHeaderAnchor,
  #loginImage {
    color: var(--se-light);
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-decoration: none;
    font-size: 1em;
    margin: 0 0.5em;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
  }

  .topHeaderAnchor:hover,
  #loginImage:hover {
    color: var(--se-dark);
    font-size: 1.1em;
  }

  #phoneImageSVG,
  #loginImage {
    width: 1.25em;
    height: auto;
    margin-right: 0.3em;
    display: inline-block;
    vertical-align: middle;
    fill: currentColor; /* This ensures the SVG takes on the color of its parent */
  }

  #phoneNumber {
    font-size: 1em;
    text-decoration: none;
    color: var(--se-light);
  }

  @media only screen and (max-width: 1200px) {
    #topHeader {
      font-size: calc(10px + 0.5vw);
    }
  }

  @media only screen and (max-width: 768px) {
    #topHeader {
      font-size: calc(10px + 0.5vw);
    }

    #topHeaderPhoneNumber {
      margin-right: 0.5rem;
    }

    .topHeaderAnchor,
    #loginImage {
      font-size: 0.95em;
      margin: 0 0.3em;
    }
  }

  @media only screen and (max-width: 500px) {
    #topHeader {
      font-size: calc(12px + 0.5vw);
      padding: 0.7rem 0.5rem;
    }

    #topHeaderPhoneNumber {
      margin-right: 0.4rem;
    }

    .topHeaderAnchor,
    #loginImage {
      font-size: 1em;
      margin: 0 0.25em;
    }

    #phoneImageSVG,
    #loginImage {
      width: 1.2em;
      height: 1.2em;
      margin-right: 0.3em;
    }

    .columnContainer {
      gap: 0.5rem;
    }
  }

  /* @media only screen and (max-width: 480px) { ... } */
  /* @media only screen and (max-width: 320px) { ... } */
</style>
